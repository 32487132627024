<template>
	<div class="text-center">
		<h1 class="text-error">4<i class="mdi mdi-emoticon-sad"></i>4</h1>
		<h4 class="text-uppercase text-danger mt-3">Страница не найдена</h4>

		<router-link class="btn btn-info mt-3" to="/"><i class="mdi mdi-reply"></i> Вернуться назад</router-link>
	</div>
</template>

<script>
export default {
	name: 'Error',
	data: () => {
		return {
		}
	},
	mounted() {
		log.debug('/views/empty/Error', 'mounted')
	}
};
</script>
